@keyframes spinAround
  from
    transform: rotate(0deg)
  to
    transform: rotate(359deg)

// original
@keyframes fade
  from
    opacity: 0
  to
    opacity: 1
