.recruit-content
  .content
    &>*
      +block(1.65em)
      &:not(:first-child)
        margin-top: 30px
    h1,h2,h3,h4,h5,h6
      font-size: 15px
      &:not(:first-child)
        margin-top: 50px
      +tablet
        font-size: 24px
        &:not(:first-child)
          margin-top: 80px
          +block(30px)
    p + p
      margin-top: 2em
  &.is-figure-white
    figure
      background: $white
      padding: 20px 10px
      margin-left: -30px
      margin-right: -30px
      +tablet
        padding: 40px 20px
        margin-left: 0
        margin-right: 0
