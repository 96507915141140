$title-color: $text-strong !default
$title-family: false !default
$title-size: $size-3 !default
$title-weight: $weight-semibold !default
$title-line-height: 1.125 !default
$title-strong-color: inherit !default
$title-strong-weight: inherit !default
$title-sub-size: 0.75em !default
$title-sup-size: 0.75em !default

$subtitle-color: $text !default
$subtitle-family: false !default
$subtitle-size: $size-5 !default
$subtitle-weight: $weight-normal !default
$subtitle-line-height: 1.25 !default
$subtitle-strong-color: $text-strong !default
$subtitle-strong-weight: $weight-semibold !default
$subtitle-negative-margin: -1.25rem !default

.title,
.subtitle
  +block(35px)
  word-break: break-word
  em,
  span
    font-weight: inherit
  sub
    font-size: $title-sub-size
  sup
    font-size: $title-sup-size
  .tag
    vertical-align: middle

.title
  color: $title-color
  font-size: $title-size
  font-weight: $title-weight
  line-height: $title-line-height
  +letter-spacing(0.8px)
  @if $title-family
    font-family: $title-family
  strong
    color: $title-strong-color
    font-weight: $title-strong-weight
  & + .highlight
    margin-top: -0.75rem
  &:not(.is-spaced) + .subtitle
    margin-top: $subtitle-negative-margin
  // Sizes
  @each $size in $sizes
    $i: index($sizes, $size)
    &.is-#{$i}
      font-size: $size
  &.is-1
    line-height: 1.5
  &.is-5
    line-height: 1.75
  &.is-8
    line-height: 1.67
  &.is-mb-small-mobile
    +mobile
      +block(15px)

.subtitle
  color: $subtitle-color
  font-size: $subtitle-size
  font-weight: $subtitle-weight
  line-height: $subtitle-line-height
  @if $subtitle-family
    font-family: $subtitle-family
  strong
    color: $subtitle-strong-color
    font-weight: $subtitle-strong-weight
  &:not(.is-spaced) + .title
    margin-top: $subtitle-negative-margin

// original
.pagetitle
  text-align: center
  font-size: 20px
  +letter-spacing(1px)
  +block(40px)
  @extend .is-family-secondary
  +tablet
    font-size: 40px
    +block(30px)
    +letter-spacing(2px)
  &.is-mb-small-mobile
    +mobile
      +block(15px)

.pagetitle-02
  text-align: left
  @extend .is-family-third
  &-title-01
    font-size: 24px
    +letter-spacing(1.2px)
    @extend .is-family-secondary
    line-height: 1.5
    +tablet
      font-size: 40px
      +letter-spacing(2px)
  &-title-02
    font-size: 14px
    +letter-spacing(0.7px)
  &-title-03
    font-size: 10px
    +letter-spacing(0.6px)

  &.is-section-absolute
    position: absolute
    top: 0
    left: 0
    transform: translateY(-1.5em)
    width: 100%
    +tablet
      transform: translateY(-2.5em)
