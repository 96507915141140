$button-color: $text-strong !default
$button-background-color: $scheme-main !default
$button-family: false !default

$button-border-color: $border !default
$button-border-width: $control-border-width !default

$button-padding-vertical: calc(0.5em - #{$button-border-width}) !default
$button-padding-horizontal: 1em !default

$button-hover-color: $link-hover !default
$button-hover-border-color: $link-hover-border !default

$button-focus-color: $link-focus !default
$button-focus-border-color: $link-focus-border !default
$button-focus-box-shadow-size: 0 0 0 0.125em !default
$button-focus-box-shadow-color: bulmaRgba($link, 0.25) !default

$button-active-color: $link-active !default
$button-active-border-color: $link-active-border !default

$button-text-color: $text !default
$button-text-decoration: underline !default
$button-text-hover-background-color: $background !default
$button-text-hover-color: $text-strong !default

$button-disabled-background-color: $scheme-main !default
$button-disabled-border-color: $border !default
$button-disabled-shadow: none !default
$button-disabled-opacity: 0.5 !default

$button-static-color: $text-light !default
$button-static-background-color: $scheme-main-ter !default
$button-static-border-color: $border !default

$button-colors: $colors !default

// The button sizes use mixins so they can be used at different breakpoints
=button-small
  border-radius: $radius-small
  font-size: $size-small
=button-normal
  font-size: $size-normal
=button-medium
  font-size: $size-medium
=button-large
  font-size: $size-large

.button
  @extend %control
  @extend %unselectable
  background-color: $button-background-color
  border-color: $button-border-color
  border-width: $button-border-width
  color: $button-color
  cursor: pointer
  border-radius: 0
  height: auto
  line-height: 1.25
  @if $button-family
    font-family: $button-family
    justify-content: center
    padding-bottom: $button-padding-vertical
    padding-left: $button-padding-horizontal
    padding-right: $button-padding-horizontal
    padding-top: $button-padding-vertical
    text-align: center
    white-space: nowrap
  strong
    color: inherit
    font-size: 20px
  .icon
    &,
    &.is-small,
    &.is-medium,
    &.is-large
      height: 1.5em
      width: 1.5em
    &:first-child:not(:last-child)
      +ltr-property("margin", calc(#{-1 / 2 * $button-padding-horizontal} - #{$button-border-width}), false)
      +ltr-property("margin", $button-padding-horizontal / 4)
    &:last-child:not(:first-child)
      +ltr-property("margin", $button-padding-horizontal / 4, false)
      +ltr-property("margin", calc(#{-1 / 2 * $button-padding-horizontal} - #{$button-border-width}))
    &:first-child:last-child
      margin-left: calc(#{-1 / 2 * $button-padding-horizontal} - #{$button-border-width})
      margin-right: calc(#{-1 / 2 * $button-padding-horizontal} - #{$button-border-width})
  // States
  &:hover,
  &.is-hovered
    border-color: $button-hover-border-color
    background-color: $button-hover-background-color
    color: $button-hover-color
  &:focus,
  &.is-focused
    border-color: $button-focus-border-color
    color: $button-focus-color
    &:not(:active)
      box-shadow: $button-focus-box-shadow-size $button-focus-box-shadow-color
  &:active,
  &.is-active
    border-color: $button-active-border-color
    color: $button-active-color
  // Colors
  &.is-text
    background-color: transparent
    border-color: transparent
    color: $button-text-color
    text-decoration: $button-text-decoration
    &:hover,
    &.is-hovered,
    &:focus,
    &.is-focused
      background-color: $button-text-hover-background-color
      color: $button-text-hover-color
    &:active,
    &.is-active
      background-color: bulmaDarken($button-text-hover-background-color, 5%)
      color: $button-text-hover-color
    &[disabled],
    fieldset[disabled] &
      background-color: transparent
      border-color: transparent
      box-shadow: none

  // Sizes
  // &.is-small
  //   +button-small
  // &.is-normal
  //   +button-normal
  // &.is-medium
  //   +button-medium
  // &.is-large
  //   +button-large
  &.is-minw
    max-width: 315px
    width: 100%
  // Modifiers
  &[disabled],
  fieldset[disabled] &
    background-color: $button-disabled-background-color
    border-color: $button-disabled-border-color
    box-shadow: $button-disabled-shadow
    opacity: $button-disabled-opacity
  &.is-fullwidth
    display: flex
    width: 100%
  &.is-loading
    color: transparent !important
    pointer-events: none
    &::after
      @extend %loader
      +center(1em)
      position: absolute !important
  &.is-static
    background-color: $button-static-background-color
    border-color: $button-static-border-color
    color: $button-static-color
    box-shadow: none
    pointer-events: none
  &.is-rounded
    border-radius: $radius-rounded
    padding-left: calc(#{$button-padding-horizontal} + 0.25em)
    padding-right: calc(#{$button-padding-horizontal} + 0.25em)

.buttons
  align-items: center
  display: flex
  flex-wrap: wrap
  justify-content: flex-start
  .button
    margin-bottom: 0.5rem
    &:not(:last-child):not(.is-fullwidth)
      +ltr-property("margin", 0.5rem)
  &:last-child
    margin-bottom: -0.5rem
  &:not(:last-child)
    margin-bottom: 1rem
  // Sizes
  &.are-small
    .button:not(.is-normal):not(.is-medium):not(.is-large)
      +button-small
  &.are-medium
    .button:not(.is-small):not(.is-normal):not(.is-large)
      +button-medium
  &.are-large
    .button:not(.is-small):not(.is-normal):not(.is-medium)
      +button-large
  &.has-addons
    .button
      &:not(:first-child)
        border-bottom-left-radius: 0
        border-top-left-radius: 0
      &:not(:last-child)
        border-bottom-right-radius: 0
        border-top-right-radius: 0
        +ltr-property("margin", -1px)
      &:last-child
        +ltr-property("margin", 0)
      &:hover,
      &.is-hovered
        z-index: 2
      &:focus,
      &.is-focused,
      &:active,
      &.is-active,
      &.is-selected
        z-index: 3
        &:hover
          z-index: 4
      &.is-expanded
        flex-grow: 1
        flex-shrink: 1
  &.is-centered
    justify-content: center
    &:not(.has-addons)
      .button:not(.is-fullwidth)
        margin-left: 0.25rem
        margin-right: 0.25rem
  &.is-right
    justify-content: flex-end
    &:not(.has-addons)
      .button:not(.is-fullwidth)
        margin-left: 0.25rem
        margin-right: 0.25rem
