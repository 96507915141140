.header
  position: fixed
  top: 0
  left: 0
  width: 100%
  z-index: 90
  &.is-active
    overflow: auto
  &:not(.is-active)
    height: $header-height

.header-nav
  position: absolute
  top: 0
  left: 0
  z-index: 91

.header-burger
  position: fixed
  top: 15px
  left: 6px
  z-index: 10

.header-sitelogo
  position: fixed
  top: 20px
  right: 15px
  z-index: 10

.header-menu
  display: none
  text-align: center
  font-size: 20px
  font-family: $family-secondary
  line-height: 1.4
  padding: 80px 20px
  letter-spacing: 1px
  li
    +block(30px)

// active
.header.is-active
  background: #fff
  height: 100vh
  .header-menu
    display: block
  @supports (-webkit-touch-callout: none)
    height: -webkit-fill-available
