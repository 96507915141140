$container-offset: (2 * $gap) !default
$container-max-width: $fullhd !default

.container
  flex-grow: 1
  margin: 0 auto
  position: relative
  width: auto
  padding-left: $container-offset
  padding-right: $container-offset
  max-width: (1000px + ($container-offset*2))
  &.is-fluid
    max-width: none !important
    padding-left: $container-offset
    padding-right: $container-offset
    width: 100%
  &.is-xsmall
    width: 100%
    max-width: (480px + ($container-offset*2))
  &.is-small
    width: 100%
    max-width: (640px + ($container-offset*2))
  &.is-medium
    width: 100%
    max-width: (800px + ($container-offset*2))
  &.is-padding-small-mobile
    +mobile
      padding-left: 15px
      padding-right: 15px
  // +until-widescreen
  //   &.is-widescreen:not(.is-max-desktop)
  //     max-width: min($widescreen, $container-max-width) - $container-offset
  // +until-fullhd
  //   &.is-fullhd:not(.is-max-desktop):not(.is-max-widescreen)
  //     max-width: min($fullhd, $container-max-width) - $container-offset
  // +widescreen
  //   &:not(.is-max-desktop)
  //     max-width: min($widescreen, $container-max-width) - $container-offset
  // +fullhd
  //   &:not(.is-max-desktop):not(.is-max-widescreen)
  //     max-width: min($fullhd, $container-max-width) - $container-offset
