//common
.swiper-pagination
  &.is-vertical
    right: 20px
    .swiper-pagination-bullet
      width: 11px
      height: 1px
      background: #fff
      opacity: 1
      margin: 9px 0 9px auto
      border-radius: 0
      transition: width linear 0.1s
      &-active
        width: 24px

// top
.swiper-top
  .swiper-container
    +mobile
      padding-left: $gap
      padding-right: $gap
  .swiper-slide
    +mobile
      width: 200px !important

// work
.swiper-work-detail
  .swiper-container
    width: 100%
    height: 100vh

  .swiper-slide
    display: flex
    justify-content: center
    align-items: center
    +tablet
      display: block
    a
      flex: 1
    a,
    figure
      height: 100%

    & img
      min-width: $tablet
      width: $tablet
      max-width: inherit
      +tablet
        height: 100%
        width: 100%
        min-width: $tablet
        object-fit: cover
        object-position: bottom center
