$post-content-margin-bottom: 60px
$post-content-margin-bottom-tablet: 40px

// detail
.post
  &-header
    +block(60px)
    +tablet
      +block(40px)
    &-title
      line-height: 1.78
      font-size: 18px
      text-align: center
      +letter-spacing(0.9px)
      +tablet
        font-size: 40px
        line-height: 1.5
        +letter-spacing(2px)
  &-content
    +block(40px)
    .content
      &>figure
        +block($post-content-margin-bottom)
        +tablet
          +block($post-content-margin-bottom-tablet)
        &:not(:first-child)
          margin-top: $post-content-margin-bottom
          +tablet
            margin-top: $post-content-margin-bottom-tablet
