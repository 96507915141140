.block
  +block(50px)
  +tablet
    +block(70px)

  // Modifier
  &.is-small
    +block(60px)
    +tablet
      +block(80px)

  &.is-large
    +block(80px)
    +tablet
      +block(110px)
