.is-radiusless
  border-radius: 0 !important

.is-shadowless
  box-shadow: none !important

.is-clickable
  cursor: pointer !important

.is-unselectable
  @extend %unselectable

.is-grayscale
  filter: grayscale(1)
