$section-padding: 3rem 1.5rem !default
$section-padding-small: 9rem 1.5rem !default
$section-padding-medium: 9rem 1.5rem !default
$section-padding-large: 18rem 1.5rem !default

.section
  padding: $section-padding
  // Sizes
  &.is-small
    padding: $section-padding-small
  &.is-medium
    padding: $section-padding-medium
  &.is-large
    padding: $section-padding-large

  // Modifier
  &.has-pagetitle
    padding-top: 70px
    +tablet
      padding-top: 45px
