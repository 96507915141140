$footer-background-color: $scheme-main-bis !default
$footer-color: false !default
$footer-padding: 3rem 1.5rem 6rem !default

.footer
  padding: 30px 0
  @extend .is-family-third
  text-align: center
  color: $grey
  font-size: 12px
