$loading-step: 5
.loading
  display: none
  &.is-active
    position: fixed
    top: 0
    left: 0
    display: block
    width: 100%
    height: 100%
    z-index: 999
    &>span
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      background-repeat: no-repeat
      background-position: center center
      background-size: cover
      display: block
      animation: fade 0.1s linear forwards
      opacity: 0
      @for $i from 1 through $loading-step
        &:nth-child(#{$i})
          background-image: url(../images/loading-0#{$i}.png)
          animation-delay: #{$i/10}s
      &:last-child
        background: $white
        animation-delay: 0.7s
        animation-duration: 0.5s
        animation-timing-function: ease-out

    // Modifier
    &.is-nega
      &>span
        @for $i from 1 through $loading-step
          &:nth-child(#{$i})
            background-image: url(../images/loading-nega-0#{$i}.png)
        &:last-child
          background: #1b1b1b
    &.is-primary
      &>span
        @for $i from 1 through $loading-step
          &:nth-child(#{$i})
            background-image: url(../images/loading-primary-0#{$i}.png)
        &:last-child
          background: $primary
