// ----------------------------------------------------------------
// global variables
// ----------------------------------------------------------------
// colors
$black: #000000;
$white: #ffffff;
$grey: #666666;
$grey-light: #999999;
$primary: #009beb;
$primary-light: #C2E2F9;

$custom-colors: (
  "grey-light": (
      $grey-light
  ),
  "primary-light": (
      $primary-light
  ),
);

$size-1: 40px;
$size-2: 24px;
$size-3: 20px;
$size-4: 18px;
$size-5: 16px;
$size-6: 14px;
$size-7: 13px;
$size-8: 12px;
$size-9: 11px;
$size-10: 10px;

$size-small: $size-8;
$size-normal: $size-6;
$size-medium: $size-5;
$size-large: $size-4;

$sizes: $size-1 $size-2 $size-3 $size-4 $size-5 $size-6 $size-7 $size-8 $size-9 $size-10;

$dimensions: 16 24 32;

$spacing-values: (
  "0": 0px,
  "5": 5px,
  "10": 10px,
  "15": 15px,
  "20": 20px,
  "25": 25px,
  "35": 35px,
  "40": 40px,
  "50": 50px,
  "60": 60px,
  "80": 80px
);

$border: $grey;

$gap: 15px;
$tablet: 769px;
$desktop: 1000px;
$widescreen: 980px + (2 * $gap);
$widescreen-enabled: false;
$fullhd: 1024px + (2 * $gap);
$fullhd-enabled: false;

// Typography

$family-sans-serif: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', 'メイリオ', 'Meiryo', '游ゴシック', YuGothic, 'Meiryo UI', 'ＭＳ Ｐゴシック', 'Noto Sans JP', sans-serif;
$family-serif: source-han-serif-japanese, serif;
$family-primary: $family-serif;
$family-secondary: "Didot",$family-serif;
$family-third: "Helvetica",$family-sans-serif;

// body
$body-family: $family-serif;
$body-size: 14px;
$body-color: $black;
$body-line-height: 1.75;

// container
$container-offset: 30px;

// icon
$icon-dimensions: 20px ;
// $icon-dimensions-small: 1rem ;
// $icon-dimensions-medium: 2rem ;
// $icon-dimensions-large: 3rem ;


// hr
$hr-background-color: #ddd;
$hr-height: 1px;
$hr-margin: 25px 0;

// text
$text: $black;

// link
$link: $black;
$link-hover: $grey;

// button
$button-family: $family-third;
$button-padding-horizontal: 10px;
$button-padding-vertical: 10px;
$button-color: $primary;
$button-background-color: $white;
$button-border-color: $white;
$border-border-width: 1px;
$button-hover-color: $white;
$button-hover-border-color: $white;
$button-hover-background-color: $primary;

//control
$control-height: 46px;
$control-height-mobile: 36px;

$radius: 4px;

// coumn
$column-gap: 10px;

// block
$block-spacing: 3rem;

// setion
$section-padding:80px 0;
$section-padding-large:125px 0;
$section-padding-medium:40px 0;
$section-padding-small:20px 0;

// breadcrumb
$breadcrumb-item-separator-color: $black;

//title
$title-size: 16px;
$title-color: inherit;
$title-family: $family-secondary;
$title-weight:normal;
$title-line-height: 2;
$title-strong-color: inherit;
$title-strong-weight: inherit;
$title-sub-size: 0.75em;
$title-sup-size: 0.75em;

// content
$content-heading-color: inherit;
$content-heading-weight: normal;

//pagination
$pagination-color: $text;
$pagination-border-color: none;
$pagination-margin: 80px 0;
$pagination-min-width: $control-height;

$pagination-item-font-size: 16px;
$pagination-item-margin: 0px;
$pagination-item-padding-left: 20px;
$pagination-item-padding-right: 20px;

// $pagination-hover-color: $link-hover;
$pagination-hover-border-color:none;

// $pagination-focus-color: $link-focus;
// $pagination-focus-border-color: $link-focus-border;

// $pagination-active-color: $link-active;
// $pagination-active-border-color: $link-active-border;

// $pagination-disabled-color: $text-light;
// $pagination-disabled-background-color: $border;
// $pagination-disabled-border-color: $border;

// $pagination-current-color: $link-invert;
// $pagination-current-background-color: $link;
// $pagination-current-border-color: $link;

// $pagination-ellipsis-color: $grey-light;

// $pagination-shadow-inset: inset 0 1px 2px rgba($scheme-invert, 0.2);

// footer
$footer-background-color: $white;

// header
$header-height: 80px;