$list-margin-bottom: 80px

$list-01-margin-horizontal: 20px
$list-01-margin-top: 75px
$list-01-margin-top-tablet: 80px

$list-02-margin-horizontal: 10px
$list-02-margin-horizontal-tablet: 20px
$list-02-margin-top: 45px
$list-02-margin-top-tablet: 80px

$list-03-margin-horizontal: 6px
$list-03-margin-horizontal-tablet: 19px
$list-03-margin-top: 12px
$list-03-margin-top-tablet: 38px

$list-04-margin-horizontal: 20px
$list-04-margin-top: 60px
$list-04-margin-top-tablet: 80px

$list-05-margin-horizontal: 20px
$list-05-margin-top: 40px
$list-05-margin-top-tablet: 80px

// extend
=list-common()
  +block(80px)
  &-item
    &-image
      +block(20px)
      +tablet
        +block(35px)

// list
.list-01
  text-align: center
  margin-top: -($list-01-margin-top)
  +list-common
  +tablet
    margin-top: -($list-01-margin-top-tablet)
  &-item
    padding-top: $list-01-margin-top
    display: block
    +tablet
      padding-top: $list-01-margin-top-tablet
    &-title
      font-size: 14px
      line-height: 1.5
      +block(5px)
      +tablet
        font-size: 20px
    &-name
      font-size: 12px
      line-height: 1.4
      +tablet
        font-size: 14px
    &-sub
      font-size: 10px
      @extend .is-family-third
      +block(5px)

// 2columns
.list-02
  text-align: center
  display: flex
  flex-wrap: wrap
  margin-left: -($list-02-margin-horizontal)
  margin-right: -($list-02-margin-horizontal)
  margin-top: -($list-02-margin-top)
  +list-common
  +tablet
    margin-left: -($list-02-margin-horizontal-tablet)
    margin-right: -($list-02-margin-horizontal-tablet)
    margin-top: -($list-02-margin-top-tablet)
  &-item
    padding: 0 $list-02-margin-horizontal
    padding-top: $list-02-margin-top
    display: block
    width: 50%
    +tablet
      padding: 0 $list-02-margin-horizontal-tablet
      padding-top: $list-02-margin-top-tablet
    &-image
      +block(10px)
      +tablet
        +block(20px)
    &-title
      font-size: 12px
      line-height: 1.5
      +block(5px)
    &-name
      font-size: 10px
      line-height: 1.4

  &.is-information
    margin-top: -20px
    +tablet
      margin: -54px -27px 0
    .list-02-item
      padding-top: 20px
      +tablet
        padding: 54px 27px 0
      &-link
        background: $primary-light
        position: relative
        dl
          position: absolute
          top: 0
          left: 0
          right: 0
          bottom: 0
          display: flex
          justify-content: center
          align-items: center
          flex-direction: column
          font-size: 10px
          padding: 5px
          dt
            font-size: 24px
            @extend .is-family-secondary
            +tablet
              font-size: 60px

// 3columns
.list-03
  text-align: center
  display: flex
  flex-wrap: wrap
  margin-left: -($list-03-margin-horizontal)
  margin-right: -($list-03-margin-horizontal)
  margin-top: -($list-03-margin-top)
  +block(50px)
  +tablet
    margin-left: -($list-03-margin-horizontal-tablet)
    margin-right: -($list-03-margin-horizontal-tablet)
    margin-top: -($list-03-margin-top-tablet)
    +block(80px)
  &-item
    padding-top: $list-03-margin-top
    display: block
    width: 25%
    padding: 0 $list-03-margin-horizontal
    padding-top: $list-03-margin-top
    +tablet
      padding: 0 $list-03-margin-horizontal-tablet
      padding-top: $list-03-margin-top-tablet
    &-image
      +block(10px)
      +tablet
        +block(20px)
    &-title
      font-size: 12px
      line-height: 1.5
      +block(5px)
    &-name
      font-size: 10px
      line-height: 1.4

// tablet:3columns / mobile:1column
.list-04
  text-align: left
  margin-top: -($list-04-margin-top)
  +list-common
  +tablet
    display: flex
    flex-wrap: wrap
    margin-top: -($list-04-margin-top-tablet)
    margin-left: -($list-04-margin-horizontal)
    margin-right: -($list-04-margin-horizontal)
  &-item
    padding-top: $list-04-margin-top
    display: flex
    flex-direction: column
    justify-content: space-between
    +tablet
      width: 33.3%
      padding: 0 $list-04-margin-horizontal
      padding-top: $list-04-margin-top-tablet
    &>*
      &:nth-child(1)
        +block(10px)
        +tablet
          +block(20px)
    &-image
      +block(20px)
    &-title
      text-align: center

// reqruite
.list-05
  text-align: center
  margin-top: -($list-05-margin-top)
  +list-common
  +tablet
    margin-top: -($list-05-margin-top-tablet)
  &-item
    padding-top: $list-05-margin-top
    display: block
    +tablet
      padding-top: $list-05-margin-top-tablet
    &-title
      text-align: left
      font-size: 18px
      line-height: 2
    &-name
      font-size: 16px
      text-align: right
    &-sub
      text-align: right
      font-size: 10px
      @extend .is-family-third
      +block(5px)

// reqruite
.list-information
  text-align: center
  margin-top: -($list-05-margin-top)
  +list-common
  +tablet
    margin-top: -($list-05-margin-top-tablet)
  &-item
    padding-top: $list-05-margin-top
    display: block
    +tablet
      padding-top: $list-05-margin-top-tablet
    &-title
      text-align: left
      font-size: 18px
      line-height: 2
    &-name
      font-size: 16px
      text-align: right
    &-sub
      text-align: right
      font-size: 10px
      @extend .is-family-third
      +block(5px)

// data
.list-data
  &-item
    +block(40px)
    &>dt
      background: $primary
      color: $white
      padding: 8px 10px
      font-size: 16px
    &>dd
      padding: 10px 0
      @extend .is-family-third
  &[class*="has-background-"]
    .list-data-item
      margin-bottom: 0
      &>dd
        padding: 10px

// note
.list-note
  list-style-type: none !important
  margin-left: 0 !important
  &>li
    position: relative
    padding-left: 1.5em
    +block(0.5em)
    &::before
      content: "※"
      position: absolute
      top: 0
      left: 0
